<template>
  <div class="map">
    <div style="width: 100%; height: 500px" id="allmap" ref="allmap"></div>
<!--    <amap></amap>-->
  </div>

</template>

<script>
export default {
  name: "ditu",
  data(){
    return {

    }
  },
  mounted() {
    var map = new BMapGL.Map(this.$refs.allmap); // 创建地图实例
    var point = new BMapGL.Point(104.608604,35.607173); // 创建点坐标
    map.centerAndZoom(point, 15); // 初始化地图，设置中心点坐标和地图级别
    map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
    map.setHeading(64.5); //设置地图旋转角度
    map.setTilt(40); //设置地图的倾斜角度
    var scaleCtrl = new BMapGL.ScaleControl(); // 添加比例尺控件
    map.addControl(scaleCtrl);
    //创建地址解析器实例
    var myGeo = new BMapGL.Geocoder();
    // 将地址解析结果显示在地图上，并调整地图视野
    myGeo.getPoint(
        "定西市安定区安定路大地幼儿园西南侧",
        function (point) {
          if (point) {
            map.centerAndZoom(point, 16);
            map.addOverlay(
                new BMapGL.Marker(point, {
                  title: "定西建达职业培训学校有限公司",
                })
            );
          } else {
            alert("您选择的地址没有解析到结果！");
          }
        },
        "北京市"
    );
  },

}
</script>

<style scoped>

</style>
