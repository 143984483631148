<template>
  <div class="home">
    <div class="home-nav-bg d-none d-sm-block"></div>
    <div class="d-sm-none">
      <img src="@/assets/dxjd.jpg" alt="" class="img-fluid" srcset="">
    </div>

    <div class="">
      <div class="container">
        <el-divider content-position="left">{{ title }}</el-divider>
        <div class="list-unstyled d-none d-sm-block">
          <div class="media my-4 text-left cursor-pon" v-for="(item,index) in lbtNewsList" :key="item.id" @click="details(item
          )">
            <img :src="item.image" class="mr-3" alt="" style="width: 134px;height: 100px">
            <div class="media-body">
              <h5 class="my-2">{{ item.title }}</h5>
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
        <div class="list-unstyled d-block d-sm-none">
          <div class="media2 my-4" v-for="(item,index) in lbtNewsList" @click="details(item
          )">
            <img :src="item.image" class="img-fluid" alt="">
            <div class="media-body">
              <h5 class="my-2">{{ item.title }}</h5>
              <p>{{ item.desc }}</p>
            </div>
          </div>

        </div>
        <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="currpage"
            :page-size="query.row"
            layout="total, prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="hidden-sm-and-down" style="padding-top: 170px"></div>
    <div class="hidden-sm-and-down home-foot py-1">
      <div class="hidden-sm-and-down home-foot-head py-3 d-flex justify-content-around mx-auto">
        <el-select style="text-align: center!important;" placeholder="----国家部委----"
                   @change="gjbwSelect" v-model="gjbwData">
          <el-option
              style="text-align: center!important;"
              v-for="item in gjbw"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
        <el-select placeholder="----省政府各部门----" @change="gjbwSelect" v-model="szfgbmData">
          <el-option
              style="text-align: center!important;"
              v-for="item in szfgbm"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
        <el-select placeholder="----建设行业业务单位----" @change="gjbwSelect" v-model="jshyywdwData">
          <el-option
              style="text-align: center!important;"
              v-for="item in jshyywdw"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
        <el-select placeholder="----合作企业----" @change="gjbwSelect" v-model="hzqyData">
          <el-option
              style="text-align: center!important;"
              v-for="item in hzqy"
              :key="item.id"
              :label="item.cate"
              :value="item.site_url">
          </el-option>
        </el-select>
      </div>
      <!--      大屏-->
      <el-row class=" home-foot-main mx-auto pt-2">
        <el-col class="text-center pr-2">公司：定西建达职业培训学校有限公司</el-col>
      </el-row>
      <el-row class="home-foot-main mx-auto pt-2">
        <el-col class="text-center pr-2">Copyright © 2020- 2021 甘肃省定西市安定区新城大道小学2幢3楼 All Rights
          Reserved
        </el-col>
      </el-row>
      <el-row class="home-foot-main mx-auto pt-2">
        <el-col :sm="8" :md="8" class="text-center pr-2 cursor-pon"><span
            @click="beianhao">备案号：陇ICP备2020004530号-1</span></el-col>
        <el-col :sm="8" :md="8" class="text-center pr-2">公安备案：<img
            src="@/assets/ga.jpg" alt=""> 甘公网安备
          <span class="cursor-pon" @click="gongan">62110202000251号</span>
        </el-col>
        <el-col :sm="8" :md="8" class="text-center pr-2">咨询热线：18993191111</el-col>
      </el-row>
    </div>
    <div class="hidden-sm-and-up home-foot2 py-1">
      <div class="hidden-sm-and-down home-foot-head py-3 d-flex justify-content-around mx-auto">
      </div>
      <!--      大屏-->
      <div>
        <el-row class=" home-foot-main mx-auto pt-2">
          <el-col class="text-center pr-2 text-white">公司：定西建达职业培训学校有限公司8888</el-col>
        </el-row>
        <el-row class="home-foot-main mx-auto pt-2">
          <el-col class="text-center pr-2 text-white">Copyright © 2020- 2021 甘肃省定西市安定区新城大道小学2幢3楼
            All
            Rights
            Reserved
          </el-col>
        </el-row>
        <el-row class="home-foot-main mx-auto pt-2">
          <el-col :sm="8" :md="8" class="text-center pr-2 text-white cursor-pon"><span @click="beianhao">备案号：陇ICP备2020004530号-1</span>
          </el-col>
          <el-col :sm="8" :md="8" class="text-center pr-2 text-white">公安备案：<img
              src="@/assets/ga.jpg" alt=""> 甘公网安备
            <span class="cursor-pon" @click="gongan">62110202000251号</span>
          </el-col>
          <el-col :sm="8" :md="8" class="text-center pr-2 text-white">咨询热线：18993191111</el-col>
        </el-row>
      </div>
    </div>

  </div>
</template>

<script>
import Index from '@/components/index.vue'
import gywm from '@/components/zxgk/gywm.vue'
import zxgk from '@/components/zxgk/index.vue'
import pxss from '@/components/zxgk/pxss.vue'
import jgsz from '@/components/zxgk/jgsz.vue'
import xwdt from '@/components/xwdt/index.vue'
import zcfg from '@/components/zcfg/index.vue'
import ywfw from '@/components/ywfw/index.vue'
import {getBottomLink, getNewsList} from "@/utils/user";

export default {
  name: "More",
  components: {
    Index, gywm, zxgk, pxss, jgsz, xwdt, zcfg, ywfw
  },
  props: {
    menuList: {
      // 模拟数据

    }
  },
  data() {
    return {
      title: '',
      minHeight: 0,
      linkModel: '',
      //   页脚数据
      footerLink: [
        {
          value: '选项1',
          label: '111'
        }, {
          value: '选项2',
          label: '222'
        }
      ],
      total: 0,
      currpage: 0,
      activeName: '',
      zcfgList: [],
      lbtNewsList: [],
      lbtNewsList2: [],
      lbtNewsList3: [],
      query: {
        type: 'zxxw',
        row: 4,
        page: 1
      },
      //     国家部委
      gjbw: [],
      gjbwData: null,
      // 省政府各部门
      szfgbm: [],
      szfgbmData: null,
      // 建设行业业务单位
      jshyywdw: [],
      jshyywdwData: null,
      hzqy: [],
      hzqyData: null
    }
  },
  mounted() {
    console.log('家来了')
    console.log(localStorage.getItem('newsType'))
    // console.log('333',localStorage.getItem('listActive'))
    // console.log(localStorage.getItem('isListActiveChilden'))
    // if(localStorage.getItem('listActive')!=null){
    //   this.isActive=localStorage.getItem('listActive')
    //   console.log('进来888了',this.isActive)
    //   // console.log(localStorage.getItem('isListActiveChilden'))
    //   if(localStorage.getItem('isListActiveChilden')!=null){
    //     console.log('进来了')
    //     this.isActiveChilden=localStorage.getItem('isListActiveChilden')
    //   }
    // }
    //     获取底部导航栏数据
    this.getBottomNav()
    this.title = localStorage.getItem('newsType')
    if (localStorage.getItem('newsType') == '最新新闻') {
      this.query.type = 'zxxw'
      this.getZxzx()
    }
    if (localStorage.getItem('newsType') == '培训通知') {
      this.query.type = 'pxtz'
      this.getZxzx2()
    }
  },
  created() {
  },
  methods: {

    handleSelect(key, keyPath) {
      console.log(key)
      console.log(keyPath[0])
      // 默认首页
      if (key == 1) {
        this.isJiHuo = true
      } else {
        this.isJiHuo = false
      }
      this.isActive = keyPath[0]
      this.isActiveChilden = key
      localStorage.setItem("listActive", keyPath[0])
      localStorage.setItem("isListActiveChilden", key)
      //  宽度大于576，就去掉点击事件
      if (window.innerWidth < 576) {
        document.getElementById("zkbtn").click()
      }
      //   证书查询跳转
      if (this.isActive == 6) {
        window.open("https://edu.gsdxjd.com/")
      }
    },
    getZxzx() {
      getNewsList(this.query).then(res => {
        if (res.code == 200) {
          this.lbtNewsList = res.data.list
          this.total = res.data.count
          this.currpage = res.data.currpage
        }
      })
    },
    getZxzx2() {
      getNewsList(this.query).then(res => {
        if (res.code == 200) {
          this.lbtNewsList = res.data.list
          this.total = res.data.count
          this.currpage = res.data.currpage
        }
      })
    },
    getZxzx3() {
      getNewsList(this.query).then(res => {
        if (res.code == 200) {
          this.lbtNewsList = res.data.list
          this.total = res.data.count
          this.currpage = res.data.currpage
        }
      })
    },
    details(dat) {
      this.$store.commit('setShowDetail',true)
      this.$store.commit('setDetailId',dat.id)
      // this.$router.push({name: 'Details', params: {data: dat.id}})
    },

    handleCurrentChange(e) {
      this.query.page = e
      if (localStorage.getItem('newsType') == '最新新闻') {
        this.query.type = 'zxxw'
        this.getZxzx()
      }
      if (localStorage.getItem('newsType') == '培训通知') {
        this.query.type = 'pxtz'
        this.getZxzx2()
      }

    },
    gongan() {

      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=62110202000251', '_blank')
    },
    beianhao() {
      window.open('https://beian.miit.gov.cn/', '_blank')
    },
    getBottomNav() {
      //     国家部委
      getBottomLink({
        cate: '国家部委'
      }).then(res => {
        this.gjbw = res.data
      })
      //     省政府各部门
      getBottomLink({
        cate: '省政府各部门'
      }).then(res => {
        this.szfgbm = res.data
      })
      // 建设行业业务单位
      getBottomLink({
        cate: '建设行业业务单位'
      }).then(res => {
        this.jshyywdw = res.data
      })
      // 合作企业
      getBottomLink({
        cate: '合作企业'
      }).then(res => {
        this.hzqy = res.data
      })
    },
    visibleChange(e) {
      console.log(e)
    },
    gjbwSelect(e) {
      console.log(e)
      window.location.href = e
    },

  }
};
// 这部分样式自己按需修改或删除
</script>
<style lang="scss" scoped>
.container {
  padding: 0 !important;
}

.home {
  position: relative;
}

::v-deep .is-active {
  background: #0c5460;
}

.navbar {
  padding: 0 !important;
}

::v-deep .is-active {
  background-color: #009195 !important;
}

.home-nav-bg {
  background: url("../assets/b1.jpg") no-repeat;
  //background: url("https://www.gsdxjd.com/uploads/image/20200729/1595992779.jpg") no-repeat;
  height: 200px;
  background-size: cover;
}

.home-nav-main {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png");
}

.el-menu--horizontal {
  width: 100%;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  background: #ffffff !important;
  //background: linear-gradient(135deg, #c7e9fb, #a6defa, #80d4f9, #5bc9f8, #35bef7);;

}

.el-submenu__title {
  font-size: 20px !important;

  &:hover {
    color: #000000 !important;
  }
}


.el-menu-item {
  text-align: center;
  font-size: 20px !important;
}

.el-menu--horizontal > .el-submenu:hover {
  outline: 0;
  color: pink !important;
}

.menu-set-class {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png") !important;
}

.menu-set-class > li {
  font-size: 20px !important;

}

//页脚
.home-foot {
  height: 195px;
  padding-top: 25px;

  background-color: #2f5494;

}

.home-foot {
  width: 100%;
  background: #2f5494;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;

  .home-foot-head {
    max-width: 1140px;
  }

  .home-foot-main {
    max-width: 1140px;
    background-color: #2f5494;
    color: #ffffff;
    font-size: 14px;
    //text-align: left;
  }
}

.home-foot2 {
  width: 100%;
  background: #2f5494;
  //position: fixed;
  //bottom: 0;
  //left: 0;
  z-index: 9999;

  .home-foot-head {
    max-width: 1140px;
  }

  .home-foot-main {
    max-width: 1140px;
    background-color: #2f5494;
    color: #ffffff;
    font-size: 14px;
    //text-align: left;
  }
}

::v-deep .el-submenu__title i {
  color: #000000;
}

.jihuo {
  background: #ffffff;
}

::v-deep .el-menu--horizontal {
  border: none !important;
}

::v-deep .el-menu-item .el-menu-item-new {
  color: red !important;
}

.el-menu-item:hover {
  color: #000000 !important;
}

nav.navbar.navbar-expand-md.bg-dark.navbar-dark {
  background: url("https://zjt.gansu.gov.cn/zjt/xhtml/images/nav_bg.png") !important;
}

::v-deep .el-menu--popup {
  background: pink !important;
}

::v-deep el-menu-item > .is-active > .jihuo_children {
  background: red !important;
}

.list-unstyled {
  .media {
    img {
      max-height: 100px;
    }
  }

  .media2 {
    //display: flex;
    //flex-wrap: wrap;

    .media-body {
      flex: 0;
    }
  }

}

::v-deep .el-divider__text {
  font-size: 16px !important;
  color: red;
  font-weight: bold;
}
</style>

